
	import { Component, Inject, Vue } from "vue-property-decorator";

	import type { RedepmtionStaffModel } from "@/models/Owner";

	@Component
	export default class extends Vue {
		@Inject() public readonly alert!: AlertMethod;
		@Inject() public readonly confirm!: ConfirmMethod;

		protected data: RedepmtionStaffModel[] | null = null;

		mounted(): void {
			this.load();
		}

		private async load() {
			this.data = null;
			this.data = await post<RedepmtionStaffModel[]>("/api/Owner/RedemptionStaff");
		}

		protected outletList(p: RedepmtionStaffModel): string {
			if(!p.Outlets.length) return "無";
			return p.Outlets.map(o => o.Name).join("、");
		}

		protected async deleteStaff(p: RedepmtionStaffModel): Promise<void> {
			if(!this.data || !await this.confirm(p.Name, "確定刪除核銷人員", "warning")) return;
			try {
				await post<boolean>("/api/Owner/RemoveRedemptionStaff", { Id: p.Id });
				this.data.splice(this.data.indexOf(p), 1);
			} catch(e) {
				if(e instanceof Error) this.alert(e.message, "刪除失敗", "error");
			}
		}
	}
