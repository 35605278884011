
	import { Component, Prop, Vue } from "vue-property-decorator";
	import { sum, purge } from "@/views/utility";

	import { EventGiveawayType } from "@/models/Owner";
	import type { BaseGiveawayModel, PromotionDetailModel } from "@/models/Owner";

	type Promotion = PromotionDetailModel<BaseGiveawayModel>;

	@Component
	export default class TicketRecord extends Vue {
		@Prop() public data!: Promotion;
		@Prop() public label!: string;
		@Prop() public unit!: string;
		@Prop(Boolean) public distributer!: boolean;

		protected sum = sum;
		protected purge = purge;

		protected get EventGiveawayType(): typeof EventGiveawayType {
			return EventGiveawayType;
		}

		protected distributerRecord(): void {
			this.$router.push("/owner/distributer/promotionRecord/" + this.data.Id);
		}
	}
