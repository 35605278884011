
	import { Component, Prop, Vue } from "vue-property-decorator";
	import StartEnd from "./StartEnd.vue";

	import type { BaseGiveawayModel } from "@/models/Owner";


	@Component({ components: { StartEnd } })
	export default class RangeDisplay extends Vue {
		@Prop() public data!: BaseGiveawayModel;
		@Prop(String) public label!: string;
		@Prop(String) public labelClass?: string;
		@Prop(Boolean) public showRemain?: boolean;
	}
