
	import { Component, Prop, Vue } from "vue-property-decorator";

	import StartEnd from "../../components/StartEnd.vue";
	import StatusText from "./StatusText.vue";
	import StatusBtn from "./StatusBtn.vue";
	import Description from "./Description.vue";

	import type { IchibanKujiAdminModel } from "@/models/owner/IchibanKuji";

	@Component({ components: { StatusText, StatusBtn, StartEnd, Description } })
	export default class Promotion extends Vue {
		@Prop() public data!: IchibanKujiAdminModel;
	}
