
	import { Component, Inject, Vue } from "vue-property-decorator";

	import type { PromotionIndexModel, PromotionOutletModel } from "@/models/Owner";

	@Component
	export default class extends Vue {

		@Inject() public readonly alert!: AlertMethod;

		protected data: PromotionIndexModel | null = null;

		mounted(): void {
			this.load();
		}

		private async load() {
			this.data = null;
			this.data = await post<PromotionIndexModel>("/api/Owner/PromotionIndex", { Id: "sharegiveaway" });
		}

		protected goTo(o: PromotionOutletModel, url: string): void {
			if(o.IsDisabled) this.alert(`門市活動停權中，若有疑問請洽 <a href="https://lin.ee/kUcmeAE" target="_blank">LeaPA 客服</a>。`, undefined, "error");
			else this.$router.push(url);
		}
	}
