
	import { Component, Prop, Vue } from "vue-property-decorator";

	import { giveawayTypeToString } from "@/models/Owner";
	import { statusText, statusClass } from "../../components/StatusString";
	import RangeDisplay from "../../components/RangeDisplay.vue";
	import Badge from "../../components/Badge.vue";
	import StartEnd from "../../components/StartEnd.vue";

	import type { BaseGiveawayModel, PromotionStatisticsModel } from "@/models/Owner";

	type Statistics = PromotionStatisticsModel<BaseGiveawayModel>;

	@Component({ components: { Badge, RangeDisplay, StartEnd } })
	export default class PromotionStatistics extends Vue {
		@Prop() public data!: Statistics;

		protected get typeText(): string {
			return giveawayTypeToString(this.data.Settings.Type);
		}

		protected get statusText(): string {
			return statusText(this.data.Status, this.data.IsActive);
		}

		protected get statusClass(): string {
			return statusClass(this.data.Status, this.data.IsActive).replace("text-", "");
		}

		protected get aStatusText(): string {
			return statusText(this.data.RedeemStatus);
		}

		protected get aStatusClass(): string {
			return statusClass(this.data.RedeemStatus).replace("text-", "");
		}
	}
