
	import { Component, Prop, Vue } from "vue-property-decorator";

	import { EventGiveawayType } from "@/models/Owner";

	import type { PromotionModel, BaseGiveawayModel } from "@/models/Owner";

	type Promotion = PromotionModel<BaseGiveawayModel>;

	@Component({ components: {} })
	export default class SetType extends Vue {
		@Prop() public data!: Promotion;

		protected get EventGiveawayType(): typeof EventGiveawayType {
			return EventGiveawayType;
		}

		protected setType(type: EventGiveawayType): void {
			this.data.Settings.Type = type;
			if(type == EventGiveawayType.Link) {
				this.data.Settings.DisplayCount = false;
			}
		}
	}
