
	import { Component, Prop, Vue } from "vue-property-decorator";

	import SettingDisplay from "./SettingDisplay.vue";
	import SettingMixed from "./SettingMixed.vue";

	import type { BaseGiveawayModel } from "@/models/Owner";

	@Component({ components: { SettingDisplay, SettingMixed } })
	export default class Settings extends Vue {
		@Prop() public data!: BaseGiveawayModel;
		@Prop() public unit!: string;
	}
