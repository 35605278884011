
	import { Component, Inject, Prop, Vue } from "vue-property-decorator";
	import QRModal from "@/components/modal/QRModal.vue";
	import RemarkModal from "../modal/RemarkModal.vue";
	import StatusBtn from "./components/StatusBtn.vue";
	import StatusText from "./components/StatusText.vue";
	import Description from "./components/Description.vue";
	import DetailCard from "./components/DetailCard.vue";

	import type { IchibanKujiAdminModel } from "@/models/owner/IchibanKuji";

	type Data = IchibanKujiAdminModel;

	@Component({ components: { RemarkModal, StatusBtn, StatusText, Description, DetailCard, QRModal } })
	export default class Page extends Vue {
		$refs!: {
			remark: RemarkModal;
			modal: QRModal;
		}

		@Inject() public alert!: AlertMethod;

		@Prop() public id!: string;

		protected data: Data | null = null;

		protected get title(): string {
			if(!this.data) return "";
			return this.data.Name;
		}

		mounted(): void {
			this.load();
		}

		private async load(): Promise<void> {
			this.data = null;
			const data = await post<Data>("/api/IchibanKuji/Detail", { Id: this.id });
			data.Records.forEach(r => r.open = false);
			this.data = data;
		}

		protected editRemark(): void {
			this.$refs.remark.model = this.data;
			this.$refs.remark.show();
		}

		protected qr(): void {
			if(!this.data) return;
			this.$refs.modal.show(this.data.Link);
		}
	}
