var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { share } from "@common/jsInterface";
import { EventGiveawayType } from "@/models/Owner";
import QRModal from "@/components/modal/QRModal.vue";
import PromotionStatusVue from "../components/PromotionStatus.vue";
import PromotionHeader from "../components/PromotionHeader.vue";
import SettingDetail from "../eventGiveaway/components/SettingDetail.vue";
import TicketRecord from "../components/TicketRecord.vue";
import RangeDisplay from "../components/RangeDisplay.vue";
import Rules from "../eventGiveaway/components/Rules.vue";
import ExcelModal from "./modal/ExcelModal.vue";
import ClaimExcelModal from "./modal/ClaimExcelModal.vue";
let prev = "";
let default_1 = class default_1 extends Vue {
    constructor() {
        super(...arguments);
        this.data = null;
        this.backUrl = "";
        this.prev = "";
    }
    beforeRouteEnter(to, from, next) {
        prev = from.path;
        next();
    }
    mounted() {
        this.load();
    }
    get LinkCode() {
        if (!this.data)
            return "";
        const match = this.data.Link.match(/[a-f0-9]+$/i);
        return match && match[0] || "";
    }
    get title() {
        if (!this.data)
            return "";
        return this.data.Name;
    }
    get EventGiveawayType() {
        return EventGiveawayType;
    }
    load() {
        return __awaiter(this, void 0, void 0, function* () {
            this.data = null;
            this.data = yield post("/api/Owner/GetPromotion", { Id: this.id, Name: "pointgiveaway" });
        });
    }
    setBack() {
        this.backUrl = prev;
    }
    qr() {
        this.$refs.qr.show(this.data.Link);
    }
    copy() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.$clipboard(this.data.Link);
            this.$refs.toast.show();
        });
    }
    excel() {
        this.$refs.excel.show();
    }
    claimExcel() {
        this.$refs.claim.show();
    }
    share() {
        if (!this.data)
            return;
        share(this.data.Link, undefined, undefined);
    }
};
__decorate([
    Inject()
], default_1.prototype, "confirm", void 0);
__decorate([
    Inject()
], default_1.prototype, "alert", void 0);
__decorate([
    Prop()
], default_1.prototype, "id", void 0);
default_1 = __decorate([
    Component({
        components: {
            QRModal, ExcelModal, ClaimExcelModal, SettingDetail, TicketRecord,
            PromotionStatusVue, PromotionHeader, RangeDisplay, Rules,
        },
    })
], default_1);
export default default_1;
