
	import { Component, Prop, Vue, Watch } from "vue-property-decorator";

	let id = 0;

	@Component
	export default class LimitNumber extends Vue {
		@Prop() public value!: number | null;

		protected id: string = 'ln' + this._uid;
		protected mode: boolean = true;

		@Watch("value") onValue(v: number): void {
			this.mode = this.value !== null;
		}

		created(): void {
			if(this.value === null) this.mode = false;
		}

		protected setUnlimited(): void {
			this.mode = false;
			this.$emit('input', null);
		}
	}
