
	import { Component, Prop, Vue } from "vue-property-decorator";
	import RemarkModal from "../modal/RemarkModal.vue";

	import type { BaseGiveawayModel, PromotionDetailModel } from "@/models/Owner";

	type Promotion = PromotionDetailModel<BaseGiveawayModel>;

	@Component({ components: { RemarkModal } })
	export default class PromotionHeader extends Vue {
		$refs!: {
			remark: RemarkModal;
		};

		@Prop() public data!: Promotion;

		protected editRemark(): void {
			this.$refs.remark.model = this.data;
			this.$refs.remark.show();
		}
	}
