
	import { Component, Inject, Prop, Vue } from "vue-property-decorator";

	import Sorter, { sort } from "@/components/Sorter.vue";
	import { setScroll } from "@/views/setScroll";
	import { PromotionStatus } from "@/models/Owner";
	import DailyModal from "../modal/dailyModal.vue";
	import PromotionStatistics from "./components/PromotionStatistics.vue";
	import type { PromotionStatisticsIndexModel, PromotionStatisticsModel, ShareGiveawayModel } from "@/models/Owner";

	import type { Selector } from "@/components/Sorter.vue";
	import type { NavigationGuardNext, Route } from "vue-router";

	type Index = PromotionStatisticsIndexModel<ShareGiveawayModel>;
	type Statistics = PromotionStatisticsModel<ShareGiveawayModel>;

	@Component({
		components: { Sorter, PromotionStatistics, DailyModal },
	})
	export default class extends Vue {

		@Inject() public readonly confirm!: ConfirmMethod;
		@Inject() public readonly alert!: AlertMethod;

		@Prop() public id!: string;

		protected data: Index | null = null;

		protected init: number = 0;
		protected filter: string | null = null;

		protected sorting: [number, boolean] = [0, false];
		protected sortingOption: string[] = [
			"創建時間", "名稱", "狀態", "啟動效期",
			// "總領取次數",
			"總領取人數",
		];
		protected sortingSelectors: Selector<Statistics>[] = [
			(a: Statistics): number => a.CreateTime.getTime(),
			(a: Statistics): string => a.AdminName,
			(a: Statistics): number => a.Status,
			(a: Statistics): number => -a.Settings.ExpireDays,
			// (a: Statistics): number => a.Statistics.ClaimCount,
			(a: Statistics): number => a.Statistics.ClaimUserCount,
		];

		mounted(): void {
			this.load();
		}

		beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext): void {
			history.replaceState({
				scroll: document.querySelector('main')!.scrollTop,
				data: this.data,
			}, "");
			next();
		}

		private async load(): Promise<void> {
			if(history.state.data) {
				this.data = history.state.data;
				const scroll = history.state.scroll;
				history.replaceState({}, "");
				Vue.nextTick(() => setScroll(scroll));
			} else {
				this.data = null;
				this.data = await post<Index>("/api/Owner/PromotionStatistics",
					{ Id: this.id, CodeName: "sharegiveaway" }
				);
			}
		}

		protected get sorted(): Statistics[] {
			if(!this.data) return [];
			var promotions = this.data.Promotions;
			if(this.filter == 'active') promotions = promotions.filter(p => p.Status == PromotionStatus.Active);
			if(this.filter == 'end') promotions = promotions.filter(p => p.Status == PromotionStatus.Expire);
			return sort(promotions, this.sortingSelectors[this.sorting[0]], this.sorting[1]);
		}

		protected add(): void {
			this.$router.push("/owner/shareGiveaway/edit/" + this.id);
		}

		protected daily(): void {
			this.$refs.daily.show();
		}
	}
