
	import { Component, Inject, Vue } from "vue-property-decorator";


	import { now, today } from "@common/date";
	import Sorter, { sort } from "@/components/Sorter.vue";
	import QRModal from "@/components/modal/QRModal.vue";
	import StartEnd from "../components/StartEnd.vue";
	import Promotion from "./components/Promotion.vue";

	import type Toast from "@common/components/widget/Toast.vue";
	import type { Selector } from "@/components/Sorter.vue";
	import type { IchibanKujiAdminModel } from "@/models/owner/IchibanKuji";

	@Component({ components: { Sorter, StartEnd, QRModal, Promotion } })
	export default class extends Vue {
		$refs!: {
			toast: Toast;
			modal: QRModal;
		};

		@Inject() public readonly alert!: AlertMethod;

		protected data: IchibanKujiAdminModel[] | null = null;
		protected now: Date = now();
		protected start: Date = today();
		protected end: Date = today().addDays(1).addSeconds(-1);

		mounted(): void {
			this.load();
		}

		private async load() {
			this.data = null;
			this.data = await post<IchibanKujiAdminModel[]>("/api/IchibanKuji");

			// this.data.push({
			// 	Name: "測試活動",
			// 	Link: "https://www.google.com",
			// 	Claimed: 10,
			// 	Id: "ABC",
			// 	Status: 0,
			// 	IsActive: true,
			// 	StartTime: new Date(2024, 6, 1),
			// 	EndTime: new Date(2024, 11, 1),
			// 	Remark: "測試備註",
			// 	Settings: {
			// 		HoleCount: 35,
			// 	},
			// } as IchibanKujiAdminModel);
		}

		get sorted(): IchibanKujiAdminModel[] {
			if(!this.data) return [];
			return sort(this.data, this.sortingSelectors[this.sorting[0]], this.sorting[1]);
		}
		protected sorting: [number, boolean] = [0, false];
		protected sortingOption: string[] = [
			"創建時間", "活動狀態", "活動名稱",
		];
		protected sortingSelectors: Selector<IchibanKujiAdminModel>[] = [
			(a: IchibanKujiAdminModel): number => a.CreateTime.getTime(),
			(a: IchibanKujiAdminModel): number => a.Status,
			(a: IchibanKujiAdminModel): string => a.Name,
		];

		protected onchange(e: [Date, Date]): void {
			if(e.length < 2) return;
			if(!e[1]) e[1] = e[0];
			[this.start, this.end] = e;
			this.load();
		}
	}
