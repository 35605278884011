
	import { Component, Inject, Prop, Vue } from "vue-property-decorator";

	import CircleStep from "@/components/CircleStep.vue";
	import { TerminalPromotionStatus } from "@/models/Owner";
	import Sorter, { sort } from "@/components/Sorter.vue";
	import DailySummary from "./components/DailySummary.vue";

	import type { PromotionTerminalIndexModel, OutletTerminalModel } from "@/models/Owner";
	import type { Selector } from "@/components/Sorter.vue";
	import type Toast from "@common/components/widget/Toast.vue";

	function dialogHTML(t: OutletTerminalModel): string {
		return `${t.MerchantKiosk && t.MerchantKiosk.Name || "未命名"}<div class="text-muted">${t.Kiosk.DisplayCode}</div>`;
	}

	@Component({
		components: { DailySummary, CircleStep, Sorter },
	})
	export default class extends Vue {
		$refs!: {
			toast: Toast;
		};

		@Inject() public readonly confirm!: ConfirmMethod;
		@Inject() public readonly alert!: AlertMethod;

		@Prop() public id!: string;

		protected data: PromotionTerminalIndexModel | null = null;

		protected sorting: [number, boolean] = [0, true];
		protected sortingOption: string[] = [
			"名稱", "機台編號", "狀態",
		];
		protected sortingSelectors: Selector<OutletTerminalModel>[] = [
			(a: OutletTerminalModel): string => a.Name,
			(a: OutletTerminalModel): string => a.Kiosk.DisplayCode,
			(a: OutletTerminalModel): number => a.Status ?? 0,
		];
		protected resumeName: string = "";

		mounted(): void {
			this.load();
		}

		private async load() {
			this.data = null;
			this.data = await post<PromotionTerminalIndexModel>("/api/Owner/PromotionTerminal", { Id: this.id, Name: "eventgiveaway" });
		}

		protected get TerminalPromotionStatus(): typeof TerminalPromotionStatus {
			return TerminalPromotionStatus;
		}

		protected count(status: TerminalPromotionStatus): number {
			return this.data!.Terminals.filter(t => t.Status == status || status == 0 && !t.Status).length;
		}

		protected get sorted(): OutletTerminalModel[] {
			if(!this.data) return [];
			return sort(this.data.Terminals, this.sortingSelectors[this.sorting[0]], this.sorting[1]);
		}

		protected async activate(t: OutletTerminalModel): Promise<void> {
			if(!await this.confirm(dialogHTML(t), "確定啟用活動送？")) return;
			try {
				await post("/api/Owner/Invite", { Id: t.Id, Name: "eventgiveaway" });
				t.Status = TerminalPromotionStatus.Activated;
				await this.alert(dialogHTML(t), "已成功啟用", "success");
			} catch(e) {
				if(e instanceof Error) await this.alert(e.message, "啟用失敗");
			}
		}

		protected async invite(t: OutletTerminalModel): Promise<void> {
			if(!await this.confirm(dialogHTML(t), "邀請成為核銷點？")) return;
			try {
				await post("/api/Owner/Invite", { Id: t.Id, Name: "eventgiveaway" });
				t.Status = TerminalPromotionStatus.Inviting;
				await this.alert(dialogHTML(t), "已送出邀請", "success");
			} catch(e) {
				if(e instanceof Error) await this.alert(e.message, "邀請失敗");
			}
		}

		protected async stop(t: OutletTerminalModel): Promise<void> {
			if(!await this.confirm(dialogHTML(t), "確定停用活動送？")) return;
			try {
				await post("/api/Owner/Stop", { Id: t.Id, Name: "eventgiveaway" });
				t.Status = TerminalPromotionStatus.Activated;
				await this.alert(dialogHTML(t), "已成功停用", "success");
				this.load();
			} catch(e) {
				if(e instanceof Error) await this.alert(e.message, "停用失敗");
			}
		}

		protected async resume(t: OutletTerminalModel): Promise<void> {
			try {
				await post("/api/Owner/Resume", { Id: t.Id, Name: "eventgiveaway" });
				t.Status = TerminalPromotionStatus.Activated;
				this.resumeName = t.Name;
				this.$refs.toast.show();
				this.load();
			} catch(e) {
				if(e instanceof Error) await this.alert(e.message, "啟用失敗");
			}
		}
	}
