
	import { Component, Inject, Prop, Vue } from "vue-property-decorator";
	import { statusText, statusClass } from "./StatusString";

	import type { PromotionDetailModel, PointGiveawayModel } from "@/models/Owner";

	type Promotion = PromotionDetailModel<PointGiveawayModel>;

	@Component
	export default class PromotionStatus extends Vue {
		@Inject() public readonly confirm!: ConfirmMethod;
		@Inject() public readonly alert!: AlertMethod;

		@Prop() public data!: Promotion;
		@Prop() public unit!: string;
		@Prop(Boolean) public redeemToggle?: boolean;

		protected get statusText(): string {
			return this.data ? statusText(this.data.Status, this.data.IsActive) : "";
		}

		protected get statusClass(): string {
			return this.data ? statusClass(this.data.Status, this.data.IsActive) : "";
		}

		protected get redeemText(): string {
			return this.data ? statusText(this.data.RedeemStatus, this.data.IsRedeemActive) : "";
		}

		protected get redeemClass(): string {
			return this.data ? statusClass(this.data.RedeemStatus, this.data.IsRedeemActive) : "";
		}

		protected async toggle(): Promise<void> {
			if(!this.data) return;
			if(!await this.confirm(
				this.data.IsActive ? `兌換連結條碼/紙本兌換券將暫停兌換。已換得的${this.unit}數不受影響。` : "兌換連結條碼/紙本兌換券將恢復使用",
				this.data.IsActive ? "活動暫停" : "活動啟用",
				"warning"
			)) return;
			try {
				await post<boolean>("api/Owner/TogglePromotion", { Id: this.data.Id });
				this.alert(this.data.IsActive ? "完成活動暫停" : "完成活動啟用", undefined, "success");
				this.data.IsActive = !this.data.IsActive;
				this.$emit("change");
			} catch(e) {
				if(e instanceof Error) await this.alert(e.message, "切換失敗", "error");
			}
		}

		protected async toggleRedeem(): Promise<void> {
			if(!this.data) return;
			if(!await this.confirm(
				this.data.IsRedeemActive ? `兌換連結條碼/紙本兌換券將暫停核銷。` : "核銷將恢復使用",
				this.data.IsRedeemActive ? "核銷暫停" : "核銷啟用",
				"warning"
			)) return;
			try {
				await post<boolean>("api/Owner/TogglePromotion", { Id: this.data.Id, Name: "redeem" });
				this.alert(this.data.IsRedeemActive ? "完成核銷暫停" : "完成核銷啟用", undefined, "success");
				this.data.IsRedeemActive = !this.data.IsRedeemActive;
				this.$emit("change");
			} catch(e) {
				if(e instanceof Error) await this.alert(e.message, "切換失敗", "error");
			}
		}
	}
