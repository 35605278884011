
	/* eslint-disable max-lines */

	import { Component, Inject, Prop, Vue } from "vue-property-decorator";

	import { toDateString } from "@common/date";
	import { EventGiveawayType } from "@/models/Owner";

	import type { EditState } from "../Edit.vue";
	import type { PromotionModel, BaseGiveawayModel } from "@/models/Owner";

	type Promotion = PromotionModel<BaseGiveawayModel>;

	const ONE_MONTH = 30;

	@Component
	export default class extends Vue {

		@Inject() public readonly confirm!: ConfirmMethod;
		@Inject() public readonly alert!: AlertMethod;

		@Prop() public data!: Promotion;
		@Prop() public state!: EditState;
		@Prop() public callback!: () => void;
		@Prop() public template!: Promotion[];

		protected ticketTemplate(): void {
			if(!this.data || !this.state.outlet) return;
			this.data.Name = this.state.outlet.Name + "紙本兌換券" + toDateString(new Date(), "");
			this.data.Settings.Type = EventGiveawayType.Ticket;
			this.state.goal = 80;
			this.data.Settings.ReceiveLimit = null;
			this.data.Settings.FixedActivations = [{
				Amount: 80,
				Count: 1,
			}];
			const today = new Date().datePart();
			const end = today.addDays(ONE_MONTH - 1);
			this.state.range = [today, end];
			this.data.Settings._range = [today, end];
			this.callback();
		}

		protected linkTemplate(): void {
			if(!this.data || !this.state.outlet) return;
			this.data.Name = this.state.outlet.Name + "兌換連結條碼" + toDateString(new Date(), "");
			this.data.Settings.Type = EventGiveawayType.Link;
			this.state.goal = 80;
			this.data.Settings.ReceiveLimit = 1;
			this.data.Settings.FixedActivations = [{
				Amount: 80,
				Count: 1,
			}];
			const today = new Date().datePart();
			const end = today.addDays(ONE_MONTH - 1);
			this.state.range = [today, end];
			this.data.Settings._range = [today, end];
			this.callback();
		}

		protected async useTemplate(el: HTMLSelectElement): Promise<void> {
			if(!this.data || !this.template) return;
			const d = this.template.find(e => e.Id == el.value);
			el.value = "";
			if(!d) return;
			Vue.set(d.Settings, "_range", []);
			this.data.Name = d.Name;
			this.data.Remark = d.Remark;
			this.data.Settings = d.Settings;
			this.$refs.settingRange.onInit();
			this.callback();
			await this.$nextTick();
			this.state.goal = this.$refs.Settings.totalCount;
		}
	}
