
	import { Component, Prop, Vue } from "vue-property-decorator";
	import DistributerItem from "./DistributerItem.vue";

	import type { DistributerModel } from "@/models/owner/Distributer";

	@Component({ components: { DistributerItem } })
	export default class Distributer extends Vue {
		@Prop() public data!: DistributerModel;
	}
