
	import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
	import * as bootstrap from 'bootstrap';

	import type { LeapaAccountModel } from '@/models/Info';

	const EXCEL_TIMEOUT = 60000;

	@Component
	export default class extends Vue {
		private modal!: bootstrap.Modal;
		protected defaultEmail: string = "";
		protected email: string = "";
		protected simple: boolean = false;

		@Inject() public alert!: AlertMethod;

		@Prop() public id!: string;

		mounted(): void {
			this.modal = new bootstrap.Modal(this.$el);
			post<LeapaAccountModel>("/api/Info").then(m => this.defaultEmail = m.ContactEmail || "");
		}

		public show(): void {
			this.email = this.defaultEmail;
			this.modal.show();
		}

		public async excel(): Promise<void> {
			try {
				await post('api/Owner/EventGiveawayClaimExcel', { Id: this.id, Email: this.email }, EXCEL_TIMEOUT);
				this.modal.hide();
				await this.alert('請至信箱查看檔案（伺服器忙碌時可能會需要約五分鐘的處理時間，若未立刻收到請稍後再試）。', "匯出成功", 'success');
				this.defaultEmail = this.email;
			} catch(e) {
				this.modal.hide();
				if(e instanceof Error) {
					await this.alert(e.message, '匯出失敗', 'error');
				}
			}
		}
	}
