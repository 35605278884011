
	import { Component, Inject, Prop, Vue } from "vue-property-decorator";
	import { share } from "@common/jsInterface";
	import { EventGiveawayType } from "@/models/Owner";
	import PromotionHeader from "../components/PromotionHeader.vue";
	import TicketRecord from "./components/TicketRecord.vue";
	import PromotionStatusVue from "./components/PromotionStatus.vue";
	import ExcelModal from "./modal/ExcelModal.vue";
	import Rules from "./components/Rules.vue";

	import type { PromotionDetailModel, ShareGiveawayModel } from "@/models/Owner";
	import type { NavigationGuardNext, Route } from "vue-router";

	type Promotion = PromotionDetailModel<ShareGiveawayModel>;

	let prev = "";
	let fromEdit = false;

	@Component({
		components: {
			ExcelModal, Rules, TicketRecord, PromotionStatusVue, PromotionHeader,
		},
	})
	export default class extends Vue {
		$refs!: {
			excel: ExcelModal;
		};

		@Inject() public readonly confirm!: ConfirmMethod;
		@Inject() public readonly alert!: AlertMethod;

		@Prop() public id!: string;

		protected data: Promotion | null = null;
		protected backUrl: string = "";
		protected prev: string = "";

		beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext): void {
			if(!from.path.toLowerCase().includes("edit")) prev = from.path;
			else fromEdit = true;
			next();
		}

		mounted(): void {
			this.load();
			if(fromEdit) this.setBack();
			fromEdit = false;
		}

		protected get LinkCode(): string {
			if(!this.data) return "";
			const match = this.data.Link.match(/[a-f0-9]+$/i);
			return match && match[0] || "";
		}

		protected get title(): string {
			if(!this.data) return "";
			return this.data.Settings.TargetPromotion.Name;
		}

		protected get EventGiveawayType(): typeof EventGiveawayType {
			return EventGiveawayType;
		}

		private async load(): Promise<void> {
			this.data = null;
			this.data = await post<Promotion>("/api/Owner/GetPromotion", { Id: this.id, Name: "sharegiveaway" });
		}

		protected setBack(): void {
			if(!this.backUrl) this.backUrl = prev;
		}

		protected excel(): void {
			this.$refs.excel.show();
		}

		protected share(): void {
			if(!this.data) return;
			share(this.data.Link, undefined, undefined);
		}

		protected edit(): void {
			this.$router.push("/owner/shareGiveaway/edit/" + this.data!.OutletId + "/" + this.id);
		}

		protected toEvent(): void {
			this.$router.push("/owner/eventgiveaway/detail/" + this.data!.Settings.TargetId);
		}

		protected sum<T>(items: T[], factory: (v: T) => number): number {
			return items.reduce((x, v) => x + factory(v), 0);
		}
	}
