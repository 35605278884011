
	import { Component, Inject, Vue } from "vue-property-decorator";

	import QRModal from "@/components/modal/QRModal.vue";
	import DailySummary from "./components/DailySummary.vue";

	import type { PromotionIndexModel, PromotionOutletModel } from "@/models/Owner";

	@Component({
		components: { DailySummary, QRModal },
	})
	export default class extends Vue {
		$refs!: {
			QR: QRModal,
		};

		@Inject() public readonly alert!: AlertMethod;

		protected data: PromotionIndexModel | null = null;
		protected url: string | null = null;

		mounted(): void {
			this.load();
		}

		private async load() {
			this.data = null;
			this.data = await post<PromotionIndexModel>("/api/Owner/PromotionIndex", { Id: "pointgiveaway" });
			this.url = await post<string>("/api/Owner/GetLiffLink");
		}

		protected goTo(o: PromotionOutletModel, url: string): void {
			if(o.IsDisabled) this.alert(`門市活動停權中，若有疑問請洽 <a href="https://lin.ee/kUcmeAE" target="_blank">LeaPA 客服</a>。`, undefined, "error");
			else this.$router.push(url);
		}

		protected QR(id: string): void {
			if(!this.url) return;
			this.$refs.QR.show(this.url + "pgr/" + id);
		}
	}
