
	import { Component, Inject, Prop, Vue } from "vue-property-decorator";
	import QRModal from "@/components/modal/QRModal.vue";
	import LogoHero from "../merchant/components/LogoHero.vue";

	import TitleModal from "./modal/TitleModal.vue";
	import type { OwnerOutletModel, OutletTerminalModel } from "@/models/Owner";

	@Component({ components: { TitleModal, LogoHero, QRModal } })
	export default class extends Vue {
		$refs!: {
			title: TitleModal;
			modal: QRModal;
		}

		@Prop(String) public id!: string;

		@Inject() public readonly alert!: AlertMethod;

		protected data: OwnerOutletModel | null = null;

		mounted(): void {
			this.load();
		}

		private async load() {
			try {
				this.data = null;
				this.data = await post<OwnerOutletModel>("/api/Owner/Outlet", { Id: this.id });
			} catch(e: unknown) {
				if(e instanceof Error) await this.alert(e.message, undefined, "error");
				this.$router.back();
			}
		}

		protected edit(k: OutletTerminalModel): void {
			this.$refs.title.model = k;
			this.$refs.title.show();
		}

		protected link(): void {
			this.$router.push("/owner/match/index/" + this.id);
		}

		protected qr(): void {
			this.$refs.modal.show(this.data!.LeapaLink);
		}
	}
