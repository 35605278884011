
	import { Component, Prop, Vue } from "vue-property-decorator";

	import { giveawayTypeToString } from "@/models/Owner";
	import { statusText, statusClass } from "../../components/StatusString";
	import Badge from "../../components/Badge.vue";

	import RangeDisplay from "../../components/RangeDisplay.vue";
	import type { PromotionStatisticsModel, PointGiveawayModel } from "@/models/Owner";

	type Statistics = PromotionStatisticsModel<PointGiveawayModel>;

	@Component({ components: { Badge, RangeDisplay } })
	export default class extends Vue {
		@Prop() public data!: Statistics;

		protected get typeText(): string {
			return giveawayTypeToString(this.data.Settings.Type);
		}

		protected get statusText(): string {
			return statusText(this.data.Status, this.data.IsActive);
		}

		protected get statusClass(): string {
			return statusClass(this.data.Status, this.data.IsActive).replace("text-", "");
		}

		protected get aStatusText(): string {
			return statusText(this.data.RedeemStatus);
		}

		protected get aStatusClass(): string {
			return statusClass(this.data.RedeemStatus).replace("text-", "");
		}
	}
