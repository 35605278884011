
	import { Component, Inject, Vue } from "vue-property-decorator";

	import PrizeSetting from "./components/PrizeSetting.vue";
	import BasicSetting from "./components/BasicSetting.vue";
	import { remain } from "./components/util";

	import type { PromotionModel } from "@/models/Owner";
	import type { IchibanKujiModel } from "@/models/owner/IchibanKuji";

	type Data = PromotionModel<IchibanKujiModel>;

	@Component({ components: { PrizeSetting, BasicSetting } })
	export default class Page extends Vue {
		$refs!: {
			prize: PrizeSetting;
		}

		@Inject() public alert!: AlertMethod;
		@Inject() public confirm!: ConfirmMethod;

		protected data: Data | null = null;

		get ok(): boolean {
			if(!this.data) return false;
			return this.data.Settings.HoleCount > 0 && // 要有洞
				remain(this.data.Settings) >= 0 && // 不能超過數量
				this.data.Settings.PointId != null; // 必須要選擇點數（不能光靠 validation 擋，因為有可能連點數都沒新增過）
		}

		mounted(): void {
			this.load();
		}

		private async load(): Promise<void> {
			this.data = null;
			this.data = await post<Data>("/api/IchibanKuji/GetEditModel");
			this.data.Settings.Price = null!;
			this.data.Settings.HoleCount = null!;
		}

		protected async send(): Promise<void> {
			if(!this.data) return;
			let msg = "新增後將無法再修改";
			const remains = remain(this.data.Settings);
			if(remains) {
				msg = `尚有 ${remains} 洞獎品未設定<br>` + msg + `<div class="mt-3 text-danger">* 當有設定獎品的號碼抽完時，即活動結束<br>消費者無法再遊戲</div>`;
			}
			if(!await this.confirm(msg, "新增活動", "warning")) return;
			const payload = { ...this.data, CreateTime: undefined };
			await post<boolean>("/api/IchibanKuji/Edit", payload);
			await this.alert("新增成功", undefined, "success");
			this.$router.back();
		}
	}
